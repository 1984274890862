import { appVersion } from './app-version';
import { Environment } from './types';

export const environment: Environment = {
    production: false,
    env: 'integration',
    version: appVersion,
    auth: {
        appId: 'viplan-designer',
        baseUrl: 'https://api-integration.viessmann.com',
        registerBaseUrl: 'https://account-integration.viessmann.com',
    },
    translationsUrls: [
        'https://api-integration.viessmann.com/translations/v2/phraseapp-proxy/projects/886503fe8403d0d43a4d16352b4b3c9d/locales/',
        'https://api-integration.viessmann.com/translations/v2/phraseapp-proxy/projects/9ff5b57bcde22e195dfde065f9255d67/locales/',
    ],
    usersUrl: 'https://api-integration.viessmann.com/users/v1/users/me',
    apiUrl: 'https://api-integration.viessmann.com/backend/viplan/v1',
    graphqlUri: 'https://api-integration.viessmann.com/projects/v1/graphql',
    pmToolUrl: 'https://viplan-na-integration.viessmann.com',
    equipmentUrl: 'https://api-integration.viessmann.com/equipment/v3',
    paywallActivated: true,
    // MockUrl will be updated in future tasks
    voucherShopUrl: 'https://viplan-na-integration.viessmann.com',
};
